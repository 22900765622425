<template>
  <div class="batchAdd-data-container">
    <!-- 顶部栏 -->
    <a-page-header style="margin-bottom: 10px" @back="handleCancel">
      <span @click="handleCancel" style="cursor: pointer;" slot="title">返回</span>
      <span slot="title" class="line"></span>
      <span slot="title">批量添加广告源</span>
    </a-page-header>
    <!-- 名称行 -->
    <div class="info-board-container">
      <div class="source-info-item">
        <div class="sourceInfo-title">应用</div>
        <div class="source-info-detail app">
          <div class="app-info-wrapper">
            <div class="app-avatar">
              <img :src="getCreativeUrl(nameInfo.iconUrl)" :alt="nameInfo.appName" />
            </div>
            <div class="app-info-detail">
              <div class="app-name">
                <AndroidSvg class="os-icon" v-if="+nameInfo.os === 1" />
                <IosSvg class="os-icon" v-else />
                {{ nameInfo.appName }}
              </div>
              <div class="app-id">ID:{{ query.appId }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="source-info-item">
        <div class="sourceInfo-title">广告位</div>
        <div class="source-info-detail other">{{ nameInfo.placeName }}</div>
      </div>
      <div class="source-info-item">
        <div class="sourceInfo-title">广告样式</div>
        <div class="source-info-detail other">{{ nameInfo.positionName }}</div>
      </div>
      <div class="source-info-item">
        <div class="sourceInfo-title">流量分组</div>
        <div class="source-info-detail other">{{ nameInfo.groupName }}</div>
      </div>
      <div class="source-info-item">
        <div class="sourceInfo-title">A/B测试分组</div>
        <div class="source-info-detail other">{{ nameInfo.testName }}</div>
      </div>
    </div>
    <div class="batchAdd_table_wrapper">
      <div class="control">
        <div class="search-wrapper">
          <!-- 选项行 -->
          <a-form-model :model="formquery" labelAlign="left" layout="inline" ref="formqueryRef">
            <a-form-model-item
              prop="platId"
              style="margin: 2px 20px 0 0"
              :rules="[
                {
                  required: true,
                  message: '请选择广告平台',
                  trigger: 'change'
                }
              ]"
            >
              <!-- 广告平台 -->
              <a-select
                class="search_item"
                placeholder="请选择广告平台"
                @change="changePlatId"
                v-model="formquery.platId"
                :filter-option="filterOption"
                showSearch
              >
                <a-select-option v-for="item in platList" :key="item.id" :value="item.id">{{
                  item.name
                }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <!-- 账户 -->
            <a-form-model-item
              prop="platAccountId"
              style="width: 180px; margin-right: 20px"
              :rules="[
                {
                  required: true,
                  message: '请选择账户',
                  trigger: 'change'
                }
              ]"
            >
              <m-select-one
                style="width: 180px; margin-left: -10px"
                :lable="false"
                :searchById="false"
                :hasOs="false"
                :hasPosition="false"
                :showId="false"
                :allData="accountList"
                v-model="formquery.platAccountId"
                @change="changePlatAccountId"
              />
            </a-form-model-item>
            <!-- 应用ID -->
            <a-form-model-item
              ref="nameRef"
              prop="platAppId"
              style="width: 180px"
              :rules="[
                {
                  required: true,
                  trigger: 'blur',
                  message: '请输入应用ID'
                }
              ]"
            >
              <a-input
                v-if="!inputEdit"
                :key="formquery.platId"
                style="margin-left: -10px; width: 150px"
                v-model.trim="formquery.platAppId"
                @input="changePlatAppId($event)"
                placeholder="请输入应用ID"
              ></a-input>
              <div v-else>
                <span>{{ formquery.platAppId }}</span>
                <a-button type="link" style="margin-left: 10px" size="small" @click="changeAllowEdit">编辑</a-button>
              </div>
            </a-form-model-item>
            <!-- 自动创建广告源 -->
            <!-- 开屏1、插屏3、激励视频5、原生自渲染4、与原生模版渲染6，原生混合 -->
            <a-form-model-item>
              <div v-if="getShowAuto(+formquery.platId, +query.positionId)">
                <a-popover
                  overlayClassName="autoPop"
                  ref="popoverRef"
                  style="display: inline-block; margin-left: 5px; margin-top: -20px; caret-color: transparent"
                  placement="bottomLeft"
                  trigger="hover"
                >
                  <template #content>
                    <div
                      style="width: 550px"
                      class="ant-popover-inner-content"
                      :getPopupContainer="() => $refs.popoverRef"
                    >
                      {{
                        `开启自动创建广告源功能后，当您在${title}后台创建广告源时，${title}会自动在${
                          +formquery.platId === 1
                            ? '穿山甲'
                            : +formquery.platId === 2
                              ? '优量汇'
                              : +formquery.platId === 3
                                ? '快手'
                                : +formquery.platId === 6
                                  ? '百度联盟'
                                  : ''
                        }后台创建广告位,无须再登录${
                          +formquery.platId === 1
                            ? '穿山甲'
                            : +formquery.platId === 2
                              ? '优量汇'
                              : +formquery.platId === 3
                                ? '快手'
                                : +formquery.platId === 6
                                  ? '百度联盟'
                                  : ''
                        }后台创建广告位`
                      }}
                    </div>
                  </template>
                  <div style="cursor: pointer">
                    <img style="width: 70%; height: 52%" src="../../../assets/images/icon.png" alt="" />
                  </div>
                </a-popover>
                {{ '自动创建广告源' }}
                <a-switch
                  v-if="!startVisible"
                  style="margin-left: 20px"
                  v-model="autoVisible"
                  @change="changeAutosts"
                />
                <span
                  v-if="startVisible"
                  style="margin-left: 20px; color: #338aff; cursor: pointer"
                  @click="handleClickEditAcount"
                >授权开启</span
                >
              </div>
            </a-form-model-item>
            <!-- appkey -->
            <!--
              sigmob or  mtg
             -->
            <a-form-model-item v-if="showAppKey(formquery.platId)" label="AppKey" prop="appKey">
              <a-input placeholder="请输入AppKey" v-model.trim="formquery.appKey" @input="changePlatAppId" />
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
      <div style="margin: 0 30px 0 20px">
        <div style="margin-bottom: 16px">
          <a-button style="margin: 10px 20px 0 2px" @click="handleAddSourceItem" type="primary">添加广告源</a-button>
          <a-button
            v-if="
              +query.positionId === 1 ||
                +query.positionId === 3 ||
                +query.positionId === 5 ||
                +query.positionId === 4 ||
                +query.positionId === 10 ||
                +query.positionId === 6
            "
            @click="handleEmptySourceItem"
            :disabled="!hasSelected"
          >批量删除</a-button
          >
          <span style="margin-left: 8px">
            <template v-if="hasSelected">
              {{ `选中 ${selectedRowKeys.length} 项` }}
            </template>
          </span>
        </div>
        <div>
          <!-- 表格行 -->
          <a-form-model :model="formquery" ref="formData" v-bind="layout">
            <a-table
              :row-selection="{ selectedRowKeys: selectedRowKeys, selectedRow: selectedRow, onChange: onSelectChange }"
              style="overflow: hidden; caret-color: transparent"
              size="middle"
              tableLayout="fixed"
              :rowKey="(record, index) => index"
              :data-source="formquery.sourceData"
              :pagination="false"
              :scroll="{ x: true }"
              :loading="isLoading"
              bordered
            >
              <!-- 操作 -->
              <a-table-column align="left" title="操作" width="150px" :fixed="true">
                <template slot-scope="text, record, index">
                  <div>
                    <a-button type="link" @click="handleCopyItem(record, index)">复制</a-button>
                    <a-button type="link" @click="handleDeleteItem(record, index)">删除</a-button>
                  </div>
                </template>
              </a-table-column>
              <!-- 广告位ID -->
              <a-table-column align="left" title="广告位ID">
                <template slot-scope="text, record, index">
                  <!-- 显示先是控制再是相反 -->
                  <a-button
                    v-if="autoVisible && getShowAuto(+formquery.platId, +query.positionId, record)"
                    type="link"
                  >
                    自动创建
                  </a-button>
                  <a-form-model-item
                    v-else
                    class="table_item"
                    :prop="`sourceData.${index}.platPlaceId`"
                    :rules="[
                      {
                        required: true,
                        message: `请输入广告位ID`,
                        trigger: 'blur'
                      }
                    ]"
                  >
                    <a-input
                      placeholder="请输入广告位ID"
                      @input="handleChangePlaceId(record)"
                      v-model.trim="record.platPlaceId"
                      style="width: 150px; caret-color: black"
                    >
                    </a-input>
                  </a-form-model-item>
                </template>
              </a-table-column>

              <!-- 竞价类型 -->
              <a-table-column title="竞价类型" align="left">
                <template slot-scope="text, record, index">
                  <a-form-model-item
                    class="table_item"
                    :prop="`sourceData[${index}].settleType`"
                    :rules="[
                      {
                        required: true,
                        message: '请选择竞价类型',
                        trigger: 'change'
                      }
                    ]"
                    title="竞价类型"
                    width="200px"
                  >
                    <a-select
                      placeholder="请选择价格类型"
                      v-model="record.settleType"
                      style="width: 120px"
                      @change="changeSettleType($event, record)"
                    >
                      <a-select-option
                        v-for="(item, i) in platsInfo[+settleTypeListId].settleTypeListType === 1
                          ? settleTypeList1
                          : settleTypeList2"
                        :key="i"
                        :value="item.id"
                      >{{ item.name }}</a-select-option
                      >
                    </a-select>
                  </a-form-model-item>
                </template>
              </a-table-column>
              <!-- 价格 -->
              <a-table-column align="left">
                <m-tips
                  slot="title"
                  :content="`未开启自动创建广告源时，需在对应的广告平台设置目标价格eCPM，此处价格仅用于瀑布流排序和计算预估收益；设置穿山甲、百度目标价格，需要联系平台商务开通权限；当价格类型选择为服务端竞价时，此价格不会回传给广告平台，当广告平台出价低于竞价底价时，${title}将会过滤此出价结果，不返回广告填充。`"
                  title="排序价格"
                  :width="250"
                  :showIcon="false"
                ></m-tips>
                <template slot-scope="text, record, index">
                  <!-- 活校验 -->
                  <a-form-model-item
                    v-if="+record.settleType === 0 || record.settleType === 1 || record.settleType === 2"
                    style="width: 220px; caret-color: black"
                    class="table_item"
                    :prop="`sourceData[${index}].bidfloor`"
                    :rules="[
                      {
                        required: true,
                        validator: validatorBidfloor,
                        trigger: 'blur'
                      }
                    ]"
                  >
                    <a-input
                      type="number"
                      step=".01"
                      style="width: 150px"
                      placeholder="请输入价格"
                      :prop="`sourceData[${index}].bidfloor`"
                      @input="changeBidfloor(record, index)"
                      v-model.trim="record.bidfloor"
                    >
                      <span slot="addonAfter">￥</span>
                    </a-input>
                  </a-form-model-item>
                  <!-- <a-button type="link" v-else-if="record.settleType === 2" >客户端竞价</a-button> -->
                </template>
              </a-table-column>
              <!-- 广告源名称 -->
              <a-table-column align="left" title="广告源名称" >
                <template slot-scope="text, record, index">
                  <a-form-model-item
                    class="table_item"
                    :prop="`sourceData[${index}].name`"
                    :rules="[
                      {
                        required: true,
                        message: '请输入广告源名称',
                        trigger: 'blur'
                      }
                    ]"
                  >
                    <a-input
                      placeholder="请输入"
                      v-model.trim="record.name"
                      :prop="`sourceData[${index}].name`"
                      @input="handleChangeName(record, index)"
                      style="width: 220px; caret-color: black"
                    >
                    </a-input>
                  </a-form-model-item>
                </template>
              </a-table-column>
              <!-- 广告源状态 -->
              <a-table-column align="left" title="广告源状态">
                <template slot-scope="text, record, index">
                  <a-form-model-item class="table_item" :prop="`sourceData[${index}].sts`">
                    <a-switch
                      style="margin-left: 20px"
                      :checked="record.sts === 'A'"
                      @change="changeSts($event, index, record)"
                    />
                  </a-form-model-item>
                </template>
              </a-table-column>
              <!-- 广告位类型 -->
              <a-table-column
                align="left"
                title="广告位类型"
                width="200px"
                v-if="
                  +query.positionId === 10 &&
                    (formquery.platId === 1 || formquery.platId === 2 || formquery.platId === 3 || formquery.platId === 6)
                "
              >
                <!-- 穿山甲、优量汇、快手、百度展示广告类型 -->
                <template slot-scope="text, record, index">
                  <a-form-model-item
                    :rules="[{ required: true, message: '请选择广告位类型', trigger: 'change' }]"
                    :prop="`sourceData[${index}].adType`"
                  >
                    <a-select
                      style="width: 180px"
                      placeholder="请选择广告位类型"
                      v-model="record.adType"
                      @change="changeAdType(record, index)"
                      showSearch
                      :filter-option="filterOption"
                    >
                      <a-select-option
                        v-for="item in adTypeList"
                        :key="item.id"
                        :value="item.id"
                        showSearch
                        :filter-option="filterOption"
                      >{{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </template>
              </a-table-column>
              <!-- 渲染方式 -->
              <a-table-column align="left" title="渲染方式" v-if="showAdStyle" width="120px">
                <template slot-scope="text, record, index">
                  <a-form-model-item
                    v-if="showAdStyleFormItem(record)"
                    class="table_item"
                    :prop="`sourceData[${index}].adStyle`"
                    :rules="[{ required: true, message: '请选择渲染方式', trigger: 'change' }]"
                  >
                    <a-select
                      style="margin: 0 15px 0 10px; width: 170px"
                      placeholder="请选择渲染方式"
                      v-model="record.adStyle"
                      showSearch
                      @change="changeAdStyle(record, index)"
                      :filter-option="filterOption"
                    >
                      <a-select-option
                        v-for="item in adStyleList(record)"
                        :key="item.id"
                        :value="item.id"
                        showSearch
                        :filter-option="filterOption"
                      >{{ item.name }}</a-select-option
                      >
                    </a-select>
                  </a-form-model-item>
                  <div style="width: 200px" v-else>-</div>
                </template>
              </a-table-column>
              <!-- 素材类型 -->
              <a-table-column width="220px" align="left" v-if="getAdCrtTypeList" title="素材类型">
                <template slot-scope="text, record, index">
                  <div v-if="!showKsAdCrtTypeList(index) && !showCsjAdCrtTypeList(index)" class="kong">-</div>
                  <a-form-model-item
                    v-else
                    class="table_item"
                    :prop="`sourceData[${index}].adCrtTypeList`"
                    :rules="[{ required: true, message: '请选择素材类型', trigger: 'change' }]"
                  >
                    <m-select-more
                      v-if="showKsAdCrtTypeList(index)"
                      style="margin-left: 5px; width: 200px"
                      v-model="record.adCrtTypeList"
                      :allData="[
                        { name: '竖版视频', id: '1' },
                        { name: '横版视频', id: '2' },
                        { name: '竖版图片', id: '5' },
                        { name: '横版图片', id: '6' }
                      ]"
                      :searchById="false"
                      :showLabel="false"
                      :hasIcon="false"
                      :showId="false"
                      width="450px"
                      label="素材类型"
                    />
                    <a-select
                      v-if="showCsjAdCrtTypeList(index)"
                      placeholder="请选择素材类型"
                      v-model="record.adCrtTypeList[0]"
                      style="width: 200px"
                    >
                      <a-select-option value="1">仅图⽚</a-select-option>
                      <a-select-option value="2">仅视频</a-select-option>
                      <a-select-option value="3">图⽚+视频</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </template>
              </a-table-column>
              <!-- 模版样式 -->
              <a-table-column align="left" title="模版样式" width="220px" v-if="showPosition && getshowTemplate">
                <template slot-scope="text, record, index">
                  <a-form-model-item
                    class="table_item"
                    v-if="showPositionWay() && getshowTemplateWay(record)"
                    :prop="`sourceData[${index}].templateIdList`"
                    :rules="[{ required: true, message: '请选择模版样式', trigger: 'change' }]"
                  >
                    <m-select-more
                      style="width: 200px"
                      v-model="record.templateIdList"
                      :allData="getTemplateList(record)"
                      :searchById="false"
                      :showLabel="false"
                      :hasIcon="false"
                      :showId="false"
                      width="450px"
                      label="模版样式"
                    />
                  </a-form-model-item>
                  <div v-else class="kong">{{ '-' }}</div>
                </template>
              </a-table-column>
              <!-- 广告样式 -->
              <a-table-column
                align="left"
                width="220px;"
                :key="testkey"
                title="广告样式"
                v-if="getShowAd && autoVisible"
              >
                <template slot-scope="text, record, index">
                  <a-form-model-item
                    v-if="
                      (+query.positionId === 4 ||
                        (+query.positionId === 10 &&
                        +record.adStyle === 2 &&
                        +record.adType !== 2 &&
                        +record.adType !== 3)) &&
                        formquery.platId === 1
                    "
                    class="table_item"
                    :prop="`sourceData[${index}].templateIdList`"
                    :rules="[{ required: true, message: '请选择广告样式', trigger: 'change' }]"
                  >
                    <m-select-more
                      style="width: 200px"
                      v-model="record.templateIdList"
                      :allData="[
                        { id: '1', name: '⼤图' },
                        { id: '2', name: '组图' },
                        { id: '3', name: '单图' },
                        { id: '4', name: '视频' },
                        { id: '5', name: '竖版视频' },
                        { id: '6', name: '竖图' }
                      ]"
                      :searchById="false"
                      :showLabel="false"
                      :hasIcon="false"
                      :showId="false"
                      width="450px"
                      label="广告样式"
                    />
                  </a-form-model-item>
                  <div style="width: 200px" v-else class="kong">{{ '-' }}</div>
                </template>
              </a-table-column>
              <!-- 广告场景 -->
              <!-- 优量汇 and (原生模板 or 原生自渲染  -->
              <a-table-column
                width="220px"
                align="left"
                v-if="+formquery.platId === 2 && (+query.positionId === 4 || +query.positionId === 6)"
                title="广告场景"
              >
                <template slot-scope="text, record, index">
                  <a-form-model-item
                    class="table_item"
                    :prop="`sourceData[${index}].scene`"
                    :rules="[{ required: true, message: '请选择广告场景', trigger: 'change' }]"
                  >
                    <a-select placeholder="请选择广告场景" v-model="record.scene" style="width: 200px">
                      <a-select-option :value="1">信息流广告</a-select-option>
                      <a-select-option :value="2">详情页插入广告</a-select-option>
                      <a-select-option :value="3">视频贴片广告</a-select-option>
                      <!-- 优量汇-原生模版一没有沉浸式视频 -->
                      <a-select-option v-if="+query.positionId !== 6" :value="4">沉浸式视频流</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </template>
              </a-table-column>
              <!--
                百度联盟6 && (原生自渲染 ｜｜ 原生模版 ｜｜ 原生混合) && autoVisible
               -->
              <a-table-column
                width="220px"
                align="left"
                v-if="
                  +formquery.platId === 6 &&
                    (+query.positionId === 4 || +query.positionId === 6 || +query.positionId === 10) &&
                    autoVisible
                "
                title="样式类型"
              >
                <template slot-scope="text, record, index">
                  <a-form-model-item
                    v-if="
                      +query.positionId === 4 ||
                        +query.positionId === 6 ||
                        (+query.positionId === 10 && +record.adType === 1 && record.adStyle)
                    "
                    class="table_item"
                    :prop="`sourceData[${index}].drawTypeList`"
                    :rules="[{ required: true, message: '请选择样式类型', trigger: 'change' }]"
                  >
                    <m-select-more
                      style="width: 200px"
                      v-model="record.drawTypeList"
                      :allData="allDrawTypeList(record)"
                      :searchById="false"
                      :showLabel="false"
                      :hasIcon="false"
                      :showId="false"
                      width="450px"
                      label="样式类型"
                      @change="changeDrawTypeList(record, $event)"
                    />
                  </a-form-model-item>
                  <span v-else>-</span>
                </template>
              </a-table-column>
              <!--
                (百度联盟6 && (原生自渲染 ｜｜ 原生混合)) && 样式类型中有图片
               -->
              <a-table-column
                width="220px"
                align="left"
                v-if="
                  +formquery.platId === 6 &&
                    (+query.positionId === 4 || +query.positionId === 10) &&
                    autoVisible
                "
                title="图片设置"
              >
                <template slot-scope="text, record, index">
                  <a-form-model-item
                    v-if="
                      (+query.positionId === 4 ||
                        (+query.positionId === 10 && +record.adType === 1 && record.adStyle === 2)) &&
                        (record.drawTypeList.includes('33') ||
                        record.drawTypeList.includes('34') ||
                        record.drawTypeList.includes('35') ||
                        record.drawTypeList.includes('36') ||
                        record.drawTypeList.includes('28') ||
                        record.drawTypeList.includes('29') ||
                        record.drawTypeList.includes('30') ||
                        record.drawTypeList.includes('42'))
                    "
                    class="table_item"
                    :prop="`sourceData[${index}].imgSizeList`"
                    :rules="[
                      {
                        required: true,
                        validator: (rule, value, callback) => {
                          validatorImgSizeList(rule, value, callback, record.drawTypeList)
                        },
                        trigger: 'change'
                      }
                    ]"
                  >
                    <m-select-more
                      style="width: 200px"
                      v-model="record.imgSizeList"
                      :allData="allImgSizeList(record)"
                      :searchById="false"
                      :showLabel="false"
                      :hasIcon="false"
                      :showId="false"
                      width="450px"
                      label="图片设置"
                    />
                  </a-form-model-item>
                  <span v-else>-</span>
                </template>
              </a-table-column>
              <!--
                (百度联盟6 && (原生自渲染 ｜｜ 原生混合)) && 样式类型中有视频
               -->
              <a-table-column
                width="220px"
                align="left"
                v-if="+formquery.platId === 6 && (+query.positionId === 4 || +query.positionId === 10) && autoVisible"
                title="视频设置"
              >
                <template slot-scope="text, record, index">
                  <a-form-model-item
                    v-if="
                      (+query.positionId === 4 ||
                        (+query.positionId === 10 && +record.adType === 1 && record.adStyle === 2)) &&
                        (record.drawTypeList.includes('37') || record.drawTypeList.includes('41'))
                    "
                    class="table_item"
                    :prop="`sourceData[${index}].videoSizeList`"
                    :rules="[
                      {
                        required: true,
                        validator: (rule, value, callback) => {
                          validatorVideoSizeList(rule, value, callback, record.drawTypeList)
                        },
                        trigger: 'change'
                      }
                    ]"
                  >
                    <m-select-more
                      style="width: 200px"
                      v-model="record.videoSizeList"
                      :allData="allVideoSizeList(record)"
                      :searchById="false"
                      :showLabel="false"
                      :hasIcon="false"
                      :showId="false"
                      width="450px"
                      label="视频设置"
                    />
                  </a-form-model-item>
                  <span v-else>-</span>
                </template>
              </a-table-column>
              <!-- 提交失败原因 -->
              <a-table-column align="center" title="提交失败原因" width="300px" v-if="msgVisible">
                <template slot-scope="record">
                  <span style="color: #e02020">
                    {{ record.msg }}
                  </span>
                </template>
              </a-table-column>
            </a-table>
          </a-form-model>
        </div>
      </div>
    </div>
    <!-- 取消和提交按钮 -->
    <div class="recommend-template-footer">
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" @click="handleSubmit">提交</a-button>
    </div>
    <AddAccountModal
      v-bind="$attrs"
      v-if="addAccountVisible"
      :visible="addAccountVisible"
      :platInfo="platInfo"
      :accountInfo="accountInfo"
      :isAddAcount="isAddAcount"
      @changeAutoCreate="changeAutoCreate"
      @modalCancel="addAccountVisible = false"
      :query="query"
      @addNewAccount="getPlatAccountList"
      @editAccount="getPlatAccountList(2)"
    />
  </div>
</template>

<script>
import { validatorImgSizeList, validatorVideoSizeList } from '../Config/components/sourceModal/sourceValidator'
import AddAccountModal from '../Config/components/addAccountModal'
import { alliancePlatformList, getNames } from '@/api/common'
import { mapState } from 'vuex'
import md5 from 'js-md5'
import IosSvg from '@/assets/icons/ios.svg?inline'
import AndroidSvg from '@/assets/icons/android.svg?inline'
import { copy } from '@/utils/string'
import { csjadTypeList, ylhadTypeList, ksadTypeList, bddTypeList, otadStyleList, bdadStyleList } from './list'
import { batchAddSource, getPlatAccounts, appBindPlat } from '@/api/aggregate'
import { initAutoCreateFormDefault } from '@/utils/initSourceAutoCreateForm'
import { showAppKey } from '@/utils/autoLabel'
export default {
  name: 'BatchAddsource',
  components: { AddAccountModal, IosSvg, AndroidSvg },
  data () {
    return {
      isLoading: false,
      testkey: '',
      // 自动创建广告源变量
      autoVisible: false,
      // 提交时保存的广告源变量
      autoSwitch: undefined,
      // 批量删除时候appId保存
      appDelId: undefined,
      // 价格和广告源名称变量
      nameVisible: true,
      // 授权开启开关变量
      startVisible: undefined,
      // 可选框选中的索引
      selectedRowKeys: [],
      // 可选框选中的行
      selectedRow: [],
      isAddAcount: false,
      platId: '',
      // 添加账户弹窗
      addAccountVisible: false,
      // 平台列表
      platList: [],
      // 接受队列
      query: {
        appId: '',
        placeId: '',
        groupId: '',
        testId: '',
        positionId: undefined
      },
      // 传入平台参数
      platInfo: {
        id: undefined
      },
      // 应用ID是否正在修改
      allowEdit: false,
      // 可编辑变量
      inputEdit: false,
      // 广告位id
      platPlaceId: undefined,
      // 输出队列
      formquery: {
        platId: undefined,
        sourceData: [],
        platAccountId: undefined,
        platAppId: undefined
      },
      layout: {
        labelCol: { span: 2 },
        wrapperCol: { span: 15 }
      },
      // 失败原因变量
      msgVisible: false,
      // 价格类型列表id
      settleTypeListId: '',
      settleTypeListType: 1,
      settleTypeList1: [
        {
          name: '固价',
          id: 0
        }
      ],
      settleTypeList2: [
        {
          name: '固价',
          id: 0
        },
        {
          name: '服务端竞价',
          id: 1
        },
        {
          name: '客户端竞价',
          id: 2
        }
      ],
      // 价格类型
      platsInfo: {
        1: {
          // 平台名
          platName: '穿山甲',
          // 账户列表
          platAccountList: [],
          // 价格类型列表
          settleTypeListType: 1,
          // SDK
          accessType: '0'
        },
        2: {
          platName: '优量汇',
          platAccountList: [],
          settleTypeListType: 2,
          accessType: '0'
        },
        3: {
          platName: '快手',
          platAccountList: [],
          settleTypeListType: 2,
          accessType: '0'
        },
        6: {
          platName: '百度',
          platAccountList: [],
          settleTypeListType: 2,
          accessType: '0'
        }
      },
      // 验证变量
      isFormqueryRule: false,
      // 是否有视频
      YLHadCrtTypeListDisabled: false,
      // 广告源名称样式
      fiexedName: '',
      accountList: [],
      nameInfo: {},
      // 起始页面
      fromPage: undefined,
      appName: '--',
      placeName: '--',
      groupName: '--',
      testName: '--',
      positionName: '--'
    }
  },
  watch: {
    // 应用id编辑不允许输入汉字和空格
    'formquery.platAppId': {
      handler (newValue, oldValue) {
        if (!newValue) {
          return
        }
        this.formquery.platAppId = newValue.replace(/[\u4e00-\u9fa5/\s+/]/g, '')
      }
    }
  },
  computed: {
    // 渲染方式展示
    showAdStyle () {
      return +this.query.positionId === 10
    },
    getShowAd () {
      let flag = false
      this.formquery.sourceData.forEach((item, index) => {
        if (
          (+this.query.positionId === 4 ||
            (+this.query.positionId === 10 && +this.formquery.sourceData[index].adStyle === 2)) &&
          this.formquery.platId === 1
        ) {
          flag = true
        }
      })
      return flag
    },
    // 展示素材类型
    getAdCrtTypeList () {
      const positionId = +this.query.positionId
      const visb = this.autoVisible
      const platId = +this.formquery.platId
      let flag = false
      this.formquery.sourceData.forEach((item) => {
        const adstyle = +item.adStyle
        if (visb) {
          // 快手 and （原生模板6 or 原生自渲染4 or (原生混合 && adSytle有值)）
          if (platId === 3 && (positionId === 4 || positionId === 6 || (positionId === 10 && adstyle))) {
            flag = true
          } else if ((positionId === 6 || (positionId === 10 && adstyle === 1)) && platId === 1) {
            // (原生模板6 || (原生混合10 && adstyle === 1)) 穿山甲
            flag = true
          }
        }
      })
      return flag
    },
    // 展示模版样式
    getshowTemplate () {
      const positionId = +this.query.positionId
      const visb = this.autoVisible
      const platId = +this.formquery.platId
      let flag = false
      this.formquery.sourceData.forEach((item, index) => {
        const adstyle = +this.formquery.sourceData[index].adStyle
        if (visb) {
          // 优量汇 (原生自渲染 || (原生混合10 && adstyle === 2))
          if (platId === 2 && (positionId === 4 || (positionId === 10 && adstyle === 2))) {
            flag = true
          } else if (
            (positionId === 6 || (positionId === 10 && adstyle === 1)) &&
            (platId === 1 || platId === 2 || platId === 3)
          ) {
            // (原生模板6 || (原生混合10 && adstyle === 1)) (穿山甲/优量汇/快手)
            flag = true
          }
        }
      })
      return flag
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      // 平台名
      title: (state) => state.autoweb.title,
      // 图片
      resourceUrl: (state) => state.autoweb.resourceUrl
    }),
    // 广告样式选中
    showPosition () {
      const p = +this.query.positionId
      return [1, 3, 4, 5, 6, 10].includes(p)
    },
    // 广告位类型选择
    adTypeList () {
      const adTypeObj = {
        1: csjadTypeList,
        2: ylhadTypeList,
        3: ksadTypeList,
        6: bddTypeList
      }
      return adTypeObj[+this.formquery.platId]
    },
    hasSelected () {
      return this.selectedRowKeys.length > 0
    }
  },
  mounted () {
    // 跳转来的选中
    this.changeGet()
    this.getNames()
    this.alliancePlatformList()
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      // 获得起始页
      vm.fromPage = from.name
    })
  },
  beforeRouteLeave (to, from, next) {
    if (!this.formquery.platAccountId) {
      next()
    } else {
      this.$confirm({
        title: '提示',
        maskClosable: true,
        content: () => {
          return (
            <div class="method-modal" style="height:100px;display:flex;padding:30px">
              <div>
                <a-icon
                  style="color: #fa8b15;font-size: 22px;margin-right: 20px;line-height:40px"
                  type="exclamation-circle"
                />
              </div>
              <span>确定离开当前页面吗？当前页面广告源信息未保存，离开后将会丢失已填写信息。</span>
            </div>
          )
        },
        onOk: () => {
          next()
        },
        icon: () => {
          return ''
        },
        class: 'method-modal-wrapper',
        width: 600
      })
    }
  },
  methods: {
  showAppKey,
  changeGet () {
       const query = this.$route.query
       this.query = query
       this.fromPage = query.fromPage
       this.query.positionId = +query.positionId
    },
    validatorImgSizeList,
    validatorVideoSizeList,
    changeDrawTypeList (record, drawTypeList) {
      // this.initAutoCreateFormDefault({ id: this.formquery.platId }, record, this.query.positionId)
    },
    allVideoSizeList (record) {
      const arr = []
      if (!(+this.query.positionId === 4 || (+this.query.positionId === 10 && +record.adStyle === 2))) {
        return []
      }
      if (record.drawTypeList.includes('37')) {
        arr.push({
          name: '视频尺寸16：9（1280x720）',
          id: '9'
        })
      }
      if (record.drawTypeList.includes('41')) {
        arr.push({
          name: '视频尺寸 9：16（720x1280）',
          id: '10'
        })
      }
      return arr
    },
    allImgSizeList (record) {
      if (!(+this.query.positionId === 4 || (+this.query.positionId === 10 && +record.adStyle === 2))) {
        return []
      }
      let arr = []
      if (record.drawTypeList.includes('33') || record.drawTypeList.includes('34')) {
        arr = [...arr, { name: '单图尺寸 2：1（600x300）', id: '1' }, { name: '单图尺寸 3：2（960x640）', id: '2' }]
      }
      if (record.drawTypeList.includes('35') || record.drawTypeList.includes('36')) {
        arr = [...arr, { name: '三图尺寸 2：1（600x300）', id: '3' }, { name: '三图尺寸 3：2（960x640）', id: '4' }]
      }
      if (
        record.drawTypeList.includes('28') ||
        record.drawTypeList.includes('29') ||
        record.drawTypeList.includes('30')
      ) {
        arr = [...arr, { name: '大图尺寸 2：1（600x300）', id: '5' }, { name: '大图尺寸16：9（1280x720）', id: '6' }]
      }
      if (record.drawTypeList.includes('42')) {
        arr = [...arr, { name: '竖图尺寸 2：3（640x960）', id: '7' }, { name: '竖图尺寸 9：16（720x1280）', id: '8' }]
      }
      return arr
    },
    allDrawTypeList (record) {
      if ((+record.adStyle === 1 && +this.query.positionId === 10) || +this.query.positionId === 6) {
        return [
          { name: '左图右文', id: '1' },
          { name: '左文右图', id: '2' },
          { name: '上图下文', id: '3' },
          { name: '上文下图', id: '4' },
          { name: '三图', id: '5' },
          { name: '竖版样式', id: '6' }
        ]
      } else if ((+record.adStyle === 2 && +this.query.positionId === 10) || +this.query.positionId === 4) {
        return [
          { name: '单图(左图右文)：图片+标题+描述', id: '33' },
          { name: '单图(右图左文)：图片+标题+描述', id: '34' },
          { name: '三图图文：图片+标题+描述', id: '35' },
          { name: '三图+logo：图片+标题+描述+logo', id: '36' },
          { name: '大图(上图下文)：图片+标题+描述', id: '28' },
          { name: '大图(上文下图)：图片+标题+描述', id: '29' },
          { name: '大图+logo：图片+标题+描述+logo', id: '30' },
          { name: '竖图：图片', id: '42' },
          { name: '视频：视频+标题+描述', id: '37' },
          { name: '竖版视频：视频+标题+描述', id: '41' }
        ]
      } else {
        return []
      }
    },
    showAdStyleFormItem (record) {
      return !(this.formquery.platId === 6 && (record.adType === 2 || record.adType === 3))
    },
    // 渲染方式
    adStyleList (item) {
      const formquery = this.formquery
      let list = []
      // 穿山甲 广告位类型选择贴片时 ｜｜ 优量汇 广告位类型选择沉浸式视频流时
      const type = (item.adType === 3 && formquery.platId === 1) || (item.adType === 4 && formquery.platId === 2)
      list = type ? (list = otadStyleList.filter((item) => item.id === 2)) : otadStyleList
      // 百度
      if (formquery.platId === 6) {
        list = bdadStyleList
      }
      // 优量汇 选择沉浸式 || 穿山甲贴片
      if ((item.adType === 4 && formquery.platId === 2) || (item.adType === 3 && formquery.platId === 1)) {
        list = [{ name: '自渲染', id: 2 }]
      }
      // 快手 draw
      if (item.adType === 2 && formquery.platId === 3) {
        list = [{ name: '模版渲染', id: 1 }]
      }
      return list
    },
    initAutoCreateFormDefault,
    // 模版样式
    showPositionWay () {
      const p = +this.query.positionId
      //  开屏1、插屏3、激励视频5、原生自渲染4、与原生模版渲染6, 原生混合
      return [1, 3, 5, 10, 4, 6].includes(p)
    },
    getshowTemplateWay (record) {
      const positionId = +this.query.positionId
      const visb = this.autoVisible
      const platId = +this.formquery.platId
      const adstyle = +record.adStyle
      // draw 混合模版 穿山甲 不显示
      if ((record.adType === 2 || record.adType === 3) && positionId === 10 && platId === 1) {
        return false
      }
      // draw 混合模版 快手 不显示
      if (record.adType === 2 && positionId === 10 && platId === 3) {
        return false
      }
      if (visb) {
        // 优量汇 (原生自渲染 || (原生混合10 && adstyle === 2))
        if (platId === 2 && (positionId === 4 || (positionId === 10 && adstyle === 2))) {
          return true
        } else if (
          (positionId === 6 || (positionId === 10 && adstyle === 1)) &&
          (platId === 1 || platId === 2 || platId === 3)
        ) {
          // (原生模板6 || (原生混合10 && adstyle === 1)) (穿山甲/优量汇/快手)
          return true
        }
      }
    },
    // 选择广告位类型
    changeAdType (e, index) {
      this.formquery.sourceData[index].adType = e.adType
      // 看是否有模版渲染
      const inStyle = this.adStyleList(e).some((item) => item.id === 1)
      this.formquery.sourceData[index].adStyle = inStyle ? 1 : 2
      this.initAutoCreateFormDefault({ id: this.formquery.platId }, e, this.query.positionId)
    },
    // 选择渲染方式
    changeAdStyle (e, index) {
      this.testkey = '' + Math.random()
      this.formquery.sourceData[index].adStyle = e.adStyle
      this.initAutoCreateFormDefault({ id: this.formquery.platId }, e, this.query.positionId)
    },
    // 模版样式
    getTemplateList (record) {
      // (原生模板6 or (原生混合10 && adstyle === 1)) 优量汇
      if (
        (+this.query.positionId === 6 || (+this.query.positionId === 10 && +record.adStyle === 1)) &&
        +this.formquery.platId === 2
      ) {
        return [
          { id: '1', name: '上图下文（支持视频）' },
          { id: '2', name: '上文下图（支持视频）' },
          { id: '3', name: '左图右文' },
          { id: '4', name: '左文右图' },
          { id: '5', name: '双图双文（支持视频）' },
          { id: '6', name: '纯图竖版' },
          { id: '7', name: '纯图横版（支持视频）' },
          { id: '8', name: '三小图双文' },
          { id: '9', name: '上文下图-文字浮层' },
          { id: '10', name: '上图下文-文字浮层' },
          { id: '11', name: '单图单文-文字浮层' }
        ]
      } else if (
        // 原生模版6 ｜｜ （混合 && 模版渲染adstyle） && 穿山甲
        (+this.query.positionId === 6 || (+this.query.positionId === 10 && +record.adStyle === 1)) &&
        this.formquery.platId === 1
      ) {
        if ((record.adCrtTypeList && record.adCrtTypeList.length === 0) || +record.adCrtTypeList[0] !== 2) {
          return [
            { id: '1', name: '上⽂下图' },
            { id: '2', name: '上图下⽂' },
            { id: '3', name: '⽂字浮层' },
            { id: '4', name: '竖版' },
            { id: '5', name: '左图右⽂' },
            { id: '6', name: '左⽂右图' },
            { id: '7', name: '三图' }
          ]
        } else if (+record.adCrtTypeList[0] === 2) {
          // 穿山甲 原生自渲染
          return [
            { id: '1', name: '上⽂下图' },
            { id: '2', name: '上图下⽂' },
            { id: '3', name: '⽂字浮层' },
            { id: '4', name: '竖版' }
          ]
        }
      } else if (
        (+this.query.positionId === 4 || (+this.query.positionId === 10 && +record.adStyle === 2)) &&
        +this.formquery.platId === 2
      ) {
        // 优量汇 and (原生自渲染4 or (原生混合10 && adStyle === 2))
        const line1 = [
          { id: '1', name: '16:9 图片' },
          { id: '2', name: '16:9 视频' },
          { id: '3', name: '3:2 三小图' },
          { id: '4', name: '9:16 图片' },
          { id: '5', name: '9:16 视频' }
        ]
        let xLine = []
        // 选择 沉浸式视频流时没有3:2三小图
        if (+record.scene === 4 || record.adType === 4) {
          xLine = line1.filter((item) => item.id !== '3')
        } else if (+record.scene === 1 || record.adType === 1) {
          // 仅选择信息流广告时 才有20:3图片
          xLine = [...line1, { id: '6', name: '20:3 图片' }]
        } else {
          xLine = line1
        }
        return xLine
      } else if (
        (+this.query.positionId === 6 || (+this.query.positionId === 10 && +record.adStyle === 1)) &&
        +this.formquery.platId === 3
      ) {
        // 快手 and (原生模板6 or (原生混合10 && adStyle === 1)) 1:竖版视频 2:横版视频 5: 竖版图片 6: 横版图片
        const obj3 = [
          { id: '1', name: '大图(支持横版图片/视频)' },
          { id: '2', name: '左文右图(支持横版图片)' },
          { id: '3', name: '左图右文(支持横版图片)' },
          { id: '4', name: '上文下图(支持横版图片/视频)' },
          { id: '5', name: '上图下文(支持横版图片/视频)' },
          { id: '14', name: '三图(支持横版图片)' },
          { id: '15', name: '三图组合(支持横版图片)' },
          { id: '16', name: '橱窗(支持横版图片)' }
        ]
        const obj = [
          { id: '17', name: '上文下图(支持竖版图片/视频)' },
          { id: '18', name: '上图下文(支持竖版图片/视频)' },
          { id: '19', name: '大图（支持竖版图片/视频' }
        ]
        const obj1 = [
          { id: '1', name: '大图(支持横版图片/视频)' },
          { id: '4', name: '上文下图(支持横版图片/视频)' },
          { id: '5', name: '上图下文(支持横版图片/视频)' }
        ]
        const list = {
          1: obj,
          5: obj,
          2: obj1,
          6: obj3
        }
        let arr = []
        record.adCrtTypeList.length > 1 &&
          record.adCrtTypeList.forEach((item) => {
            arr = [...arr, ...list[item]]
          })
        return [...new Map(arr.map((item) => [item.id, item])).values()]
      }
      return []
    },
    // 应用ID编辑
    changeAllowEdit () {
      this.allowEdit = true
      this.inputEdit = false
      // 保存原始应用ID
      this.editPlatAppId = this.formquery.platAppId
    },
    // 价格验证
    validatorBidfloor (rule, value, callback) {
      if (!value && value !== 0) {
        return callback(new Error('请输入价格'))
      } else if (value < 0.01) {
        return callback(new Error('价格必须大于等于0.01！'))
      } else {
        return callback()
      }
    },
    // 广告源开关
    changeSts (e, index, record) {
      e ? (record.sts = 'A') : (record.sts = 'S')
    },
    // 绑定账号
    async appBindPlat () {
      const currentAccount = this.accountList.find((item) => this.formquery.platAccountId === item.id)
      let type
      if (currentAccount && !currentAccount.platAppId) {
        type = 0
      } else {
        type = 1
      }
      let oldPlatAppId = ''
      if (this.allowEdit) {
        const account = this.accountList.find((item) => item.id === this.formquery.platAccountId)
        oldPlatAppId = account && account.platAppId
      } else {
        oldPlatAppId = this.formquery.platAppId ? this.formquery.platAppId : undefined
      }
      this.bindquery = {
        appId: this.query.appId,
        placeId: this.query.placeId,
        appKey: this.formquery.appKey ? this.formquery.appKey : '',
        platId: this.formquery.platId,
        platAccountId: this.formquery.platAccountId,
        platAppId: this.formquery.platAppId ? this.formquery.platAppId : '',
        oldPlatAppId: oldPlatAppId,
        type
      }
      if (
        (+this.formquery.platId === 5 ||
          +this.formquery.platId === 1027 ||
          +this.formquery.platId === 1024 ||
          +this.formquery.platId === 1031 ||
          +this.formquery.platId === 1001 ||
          +this.platList.accessType === 2) &&
        !this.bindquery.platAppId
      ) {
        this.bindquery.platAppId = md5('' + new Date().getTime() + Math.random()).slice(0, 10)
      }
      return appBindPlat(this.bindquery)
    },
    // 创建广告源
    changeAutoCreate (e) {
      this.formquery.sourceData.isAutoCreate = e.toString()
      this.formquery.sourceData.isAutoCreate === '1' ? (this.startVisible = false) : (this.startVisible = true)
      this.formquery.platId = this.platId
      this.changePlatAccountId(this.formquery.platId)
      this.getPlatAccountList(this.formquery.platId)
    },
    // 竞价类型改变
    changeSettleType (e, record) {
      if (e) {
        if (e === 1) {
          record.name =
            this.nameInfo.appName + '-' + this.systemName + '-' + this.nameInfo.positionName + '-' + '服务端竞价'
        } else if (e === 2) {
          record.name =
            this.nameInfo.appName + '-' + this.systemName + '-' + this.nameInfo.positionName + '-' + '客户端竞价'
        }
      } else {
        record.bidfloor = record.bidfloor ? record.bidfloor : ''
        record.name =
          this.nameInfo.appName + '-' + this.systemName + '-' + this.nameInfo.positionName + '-' + record.bidfloor
      }
      this.formquery.sourceData.settleType = record.settleType
    },
    // 自动创建广告源状态
    changeAutosts (e) {
      this.autoVisible = e
      this.formquery.sourceData.isAutoCreate = '0'
    },
    // 搜索框
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    // 广告平台初始化
    async handleChangePlat (platId) {
      this.platInfo = this.platList.find((item) => item.id === platId)
      this.formquery.sourceData = []
      this.inputEdit = false
      await this.getPlatAccountList()
      this.handleAddSourceItem()
    },
    // 账户列表初始化
    async getPlatAccountList (e) {
      const resp = await getPlatAccounts({
        platId: this.formquery.platId,
        appId: this.query.appId
      })
      this.accountList = resp.data || []
      // 去重
      const obj = {}
      for (const item of this.accountList) {
        if (!obj[item.id]) {
          obj[item.id] = item
        }
      }
      this.accountList = Object.values(obj)
      this.accountList.length > 0 && (this.formquery.platAccountId = this.accountList[0].id)
      this.$route.query.platAccountId && (this.formquery.platAccountId = +this.$route.query.platAccountId)
      this.changePlatAccountId(this.formquery.platAccountId)
      this.accountInfo = this.accountList.find((item) => item.id === this.formquery.platAPPId)
      if (this.accountInfo) {
        if (this.formquery.sourceData.isAutoCreate) {
          this.formquery.sourceData.isAutoCreate === 1 ? (this.startVisible = false) : (this.startVisible = true)
        }
        this.allowEdit = false
      }
    },
    // 获得名称
    async getNames () {
      const { data = {} } = await getNames({
        placeId: this.query.placeId,
        appId: this.query.appId,
        groupId: this.query.groupId,
        testId: this.query.testId,
        positionId: this.query.positionId
      })
      this.nameInfo = data || {}
      if (this.nameInfo.os === '1') {
        this.systemName = '安卓'
      } else {
        this.systemName = 'ios'
      }
    },
    // 处理应用图标
    getCreativeUrl (url) {
      if (url) {
        return this.resourceUrl + url
      } else {
        return '/images/app-none.png'
      }
    },
    //  展示素材样式1
    showKsAdCrtTypeList (index) {
      const positionId = +this.query.positionId
      const visb = this.autoVisible
      const platId = +this.formquery.platId
      const adstyle = +this.formquery.sourceData[index].adStyle
      const item = this.formquery.sourceData[index]
      // 穿山甲 混合 （draw ｜｜ 贴片）
      if (platId === 1 && positionId === 10 && (item.adType === 2 || item.adType === 3)) {
        return false
      }
      // 快手 混合 draw
      if (platId === 3 && positionId === 10 && item.adType === 2) {
        return false
      }
      if (visb) {
        // 快手 and （原生模板6 or 原生自渲染4 or (原生混合 && adSytle有值)）
        if ((positionId === 4 || positionId === 6 || (positionId === 10 && adstyle)) && platId === 3) {
          return true
        }
      }
    },
    //  展示素材样式2
    showCsjAdCrtTypeList (index) {
      const positionId = +this.query.positionId
      const visb = this.autoVisible
      const platId = +this.formquery.platId
      const adstyle = +this.formquery.sourceData[index].adStyle
      const item = this.formquery.sourceData[index]
      // 穿山甲 混合 (draw ｜｜ 贴片) 不显示
      if (platId === 1 && positionId === 10 && (item.adType === 2 || item.adType === 3)) {
        return false
      }
      // 快手 混合 draw 不显示
      if (platId === 3 && positionId === 10 && item.adType === 2) {
        return false
      }
      if (visb) {
        //   穿山甲 and (原生模板6 or (原生混合10  && adStyle===1有值))
        if ((positionId === 6 || (positionId === 10 && adstyle === 1)) && platId === 1) {
          return true
        }
      }
    },
    // 账户平台改变
    async changePlatAccountId (e) {
      // 去重
      const obj = {}
      for (const item of this.accountList) {
        if (!obj[item.id]) {
          obj[item.id] = item
        }
      }
      this.accountList = Object.values(obj)
      this.accountInfo = this.accountList.find((item) => item.id === e)
      if (this.accountInfo) {
        this.formquery.sourceData.isAutoCreate = this.accountInfo.isAutoCreate
        this.formquery.sourceData.isAutoCreate === '1' ? (this.autoVisible = true) : (this.autoVisible = false)
        this.formquery.sourceData.isAutoCreate === '0' ? (this.startVisible = true) : (this.startVisible = false)
        this.formquery.platAppId = this.accountInfo.platAppId
        this.accountInfo &&
          this.accountInfo.platAppId &&
          (this.formquery.platAppId = this.accountInfo.platAppId) &&
          (this.inputEdit = true)
        this.allowEdit = false
        if (!this.accountInfo.platAppId) {
          this.inputEdit = false
        }
        this.$refs.nameRef.clearValidate()
      }
    },
    // 跳转到创建账户
    handleClickEditAcount () {
      this.isAddAcount = false
      this.addAccountVisible = true
    },
    // 改变平台
    async changePlatId (e) {
      this.platInfo = this.platList.find((item) => item.id === e)
      if (!this.formquery.platAccountId) {
        this.platId = this.formquery.platId
        this.changePlatAccountId(e)
        this.getPlatAccountList(e)
        this.formquery.sourceData = []
        return
      }
      const th = this
      this.$confirm({
        title: '提示',
        maskClosable: true,
        content: () => {
          return (
            <div class="method-modal" style="height:100px;display:flex;padding:30px">
              <div>
                <a-icon
                  style="color: #fa8b15;font-size: 22px;margin-right: 20px;line-height:40px"
                  type="exclamation-circle"
                />
              </div>
              <span>确定切换广告平台吗？当前页面广告源信息未保存，离开后将会丢失已填写信息。</span>
            </div>
          )
        },
        async onOk () {
          const resp = await getPlatAccounts({
            platId: th.formquery.platId,
            appId: th.query.appId
          })
          th.inputEdit = false
          // 把改变后平台id保存到
          th.platId = th.formquery.platId
          th.formquery.sourceData = []
          th.formquery.platAccountId = undefined
          th.formquery.platAppId = undefined
          th.autoVisible = false
          th.formquery.sourceData.settleType = undefined
          th.platAppIdIsEdit = false
          th.accountList = resp.data || []
          th.accountList.length > 0 && (th.formquery.platAccountId = th.accountList[0].id)
          th.changePlatAccountId(th.formquery.platAccountId)
          th.$refs.formqueryRef.clearValidate()
        },
        icon: () => {
          return ''
        },
        onCancel () {
          th.formquery.platId = th.platId
        },
        class: 'method-modal-wrapper',
        width: 600
      })
    },
    // 复制应用id
    copyId (id) {
      const result = copy(id)
      if (result === false) {
        this.$message.error('不支持复制')
      } else {
        this.$message.success('复制成功')
      }
    },
    getShowAuto (platId, positionId, record) {
      // 百度 视频贴片 不可自动创建
      if (record && platId === 6 && record.adType === 2) {
        return false
      }
      if (
        (platId === 1 || platId === 2 || platId === 3 || platId === 6) &&
        (positionId === 1 ||
          positionId === 3 ||
          positionId === 5 ||
          positionId === 4 ||
          positionId === 6 ||
          positionId === 10)
      ) {
        return true
      }
    },
    // 平台列表
    async alliancePlatformList () {
      const resp = await alliancePlatformList()
      this.platList = resp.data || []
      this.platList = this.platList.filter((item) => item.accessType === '0')
      this.platList.length > 0 && (this.formquery.platId = this.platList[0].id)
      this.$route.query.platId && (this.formquery.platId = +this.$route.query.platId)
      this.platId = this.formquery.platId
      await this.handleChangePlat(this.formquery.platId)
    },
    // 选中可选框
    onSelectChange (selectedRowKeys, selectedRow) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRow = selectedRow
    },
    // 复制每一项
    handleCopyItem (item, index) {
      this.formquery.sourceData.push({
        ...JSON.parse(JSON.stringify(item)),
        num: item.num + Math.round(Math.random() * 10000)
      })
    },
    // 删除
    handleDeleteItem (record, index) {
      if (this.selectedRowKeys.length) {
        this.selectedRowKeys.length = this.selectedRowKeys.length - 1
      }
      this.formquery.sourceData.splice(index, 1)
    },
    // 添加广告源
    handleAddSourceItem () {
      if (this.formquery.platId) {
        if (this.formquery.platId === 2 || this.formquery.platId === 3 || this.formquery.platId === 6) {
          this.settleTypeListId = this.formquery.platId
        } else if (this.formquery.platId !== 2 && this.formquery.platId !== 3 && this.formquery.platId !== 6) {
          this.settleTypeListId = 1
        }
      }
      this.msgVisible = false
      // 固定名称
      this.fiexedName = this.nameInfo.appName + '-' + this.systemName + '-' + this.nameInfo.positionName + '-'
      // 随机数
      this.$set(this.formquery.sourceData, 'num', Math.round(Math.random() * 10000))
      const sourceItem = {
        num: Math.round(Math.random() * 10000),
        platAppId: undefined,
        platPlaceId: undefined,
        nameVisible: true,
        name: this.fiexedName,
        settleType: 0,
        bidfloor: undefined,
        sts: 'A',
        adStyle: 1,
        adType: 1,
        scene: undefined,
        templateIdList: [],
        isAutoCreate: undefined,
        platSourceId: undefined,
        msg: '',
        drawTypeList: [],
        imgSizeList: [],
        videoSizeList: [],
        // 快手默认素材全选 穿山甲默认图片+视频
        adCrtTypeList:
          +this.formquery.platId === 3 ? ['1', '2', '5', '6'] : +this.formquery.platId === 1 ? ['3'] : ['1']
      }
      this.initAutoCreateFormDefault({ id: this.formquery.platId }, sourceItem, this.query.positionId)
      // console.log(sourceItem)
      // this.formquery.sourceData.push(sourceItem)
      this.$set(this.formquery.sourceData, this.formquery.sourceData.length, sourceItem)
      // 素材类型必须是字符串
      this.formquery.sourceData.forEach((item) => {
        item.adCrtTypeList.forEach((a, index) => {
          item.adCrtTypeList[index] = '' + a
        })
      })
    },
    // 批量删除
    handleEmptySourceItem () {
      this.appDelId = this.formquery.platAppId
      // 对原数组的加工
      var arrIdList = this.selectedRow.map((x) => x.num)
      // 满足加工的留下,是对原数组的过滤
      this.formquery.sourceData = this.formquery.sourceData.filter((x) => !arrIdList.includes(x.num))
      this.selectedRow = []
      this.selectedRowKeys = []
    },
    // 应用id
    changePlatAppId (e) {
      if (!this.formquery.platAccountId) {
        this.$message.error('请选择账户')
        this.formquery.platAppId = ''
        return
      }
      this.formquery.platAppId = this.formquery.platAppId.replace(/[\u4e00-\u9fa5/\s+/]/g, '')
    },
    // 广告位ID
    handleChangePlaceId (record) {
      record.platPlaceId = record.platPlaceId.replace(/[\u4e00-\u9fa5/\s+/]/g, '')
      this.formquery.sourceData.platPlaceId = record.platPlaceId
    },
    // 广告源名称
    handleChangeName (record, index) {
      // 当广告源名称改变时候,价格与名称不再产生关联
      record.nameVisible = false
      record.name = record.name.replace(/[/\s+/]/g, '')
      this.formquery.sourceData.name = record.name
    },
    // 价格与广告源相关联
    changeBidfloor (record, index) {
      if (+record.settleType === 1 || +record.settleType === 2) {
        return
      }
      if (!record.bidfloor && record.bidfloor !== 0) {
        record.bidfloor = ''
      } else if (record.bidfloor < 0) {
        record.bidfloor = ''
      } else if (record.bidfloor === 0) {
        record.bidfloor = ''
      }
      this.formquery.sourceData[index].bidfloor = record.bidfloor
      if (+record.settleType === 1) {
        this.BidName = '服务端竞价'
      } else {
        this.BidName = ''
      }
      const name = this.nameInfo.appName + '-' + this.systemName + '-' + this.nameInfo.positionName + '-' + this.BidName
      // 当未输入价格的广告源名称与固定名称相同时候,名称与价格相关联
      if (record.name === name) {
        record.nameVisible = true
      }
      if (record.nameVisible) {
        if (+record.settleType === 1) {
          record.name = name
        } else {
          record.name = this.fiexedName + record.bidfloor
        }
      }
    },
    // 取消 返回
    handleCancel () {
      this.$router.push({
        name: this.fromPage,
        query: {
          appId: this.query.appId,
          placeId: this.query.placeId
        }
      })
    },
    // 提交
    async handleSubmit () {
      this.$refs.formData.validate(async (valid) => {
        if (!this.formquery.sourceData.length > 0) {
          this.$message.warning('无广告源可提交，请添加广告源！')
          return false
        }
        this.handleFormquery()
        if (!this.isFormqueryRule) {
          this.$message.warning('请完成当前表单填写！')
          return false
        }
        if (valid) {
          const positionId = Number(this.query.positionId)
          const platAccountId = this.formquery.platAccountId.toString()
          const sourceArray = { appPlatLinkList: [] }
          const params = JSON.parse(JSON.stringify(this.formquery.sourceData))
          const create = this.formquery.sourceData.isAutoCreate
          params.forEach((item) => {
            const xAd = item.adCrtTypeList
            sourceArray.appPlatLinkList.push({
              ...item,
              appId: this.query.appId,
              // sts: item.sts,
              appKey: item.appKey ? item.appKey : '',
              platId: this.formquery.platId,
              platAccountId: platAccountId,
              // platAppId: item.platAppId,
              oldPlatAppId: undefined,
              type: item.isDisabled ? 1 : 0,
              bidfloor: item.bidfloor ? item.bidfloor : 0,
              // name: item.name,
              // scene: item.scene,
              // adStyle: item.adStyle,
              // adType: item.adType,
              platPlaceId: item.platPlaceId ? item.platPlaceId : '',
              settleType: item.settleType ? item.settleType : 0,
              templateIdList: item.templateIdList || [],
              adCrtTypeList: item.adCrtTypeList ? xAd : []
              // drawTypeList: [],
              // imgSizeList: [],
              // videoSizeList: [],
            })
          })
          this.appDelId = this.formquery.platAppId
          this.isLoading = true
          this.appBindPlat(sourceArray).then(async (res) => {
            if (res.code === 200) {
              const resp = await batchAddSource({
                appId: this.query.appId,
                placeId: this.query.placeId,
                groupId: this.query.groupId,
                testId: this.query.testId,
                platId: this.formquery.platId,
                position: positionId,
                platAccountId: platAccountId,
                platAppId: this.formquery.platAppId,
                isAutoCreate: create,
                sourceData: sourceArray.appPlatLinkList
              })
              if (resp.code === 200) {
                this.$message.success('添加成功!')
                // 过滤掉成功的
                this.formquery.sourceData = this.formquery.sourceData.filter((item) =>
                  sourceArray.appPlatLinkList.forEach((x) => {
                    item.name = x.name
                  })
                )
                this.changePlatAccountId(Number(platAccountId))
                this.getPlatAccountList()
                this.changeAutosts(this.startVisible)
                this.formquery.platAppId = this.appDelId
                this.changePlatAppId()
                this.msgVisible = false
              } else if (resp.code === 201) {
                this.msgVisible = true
                // 添加msg
                this.formquery.sourceData.map((item) => {
                  return {
                    ...item,
                    msg: ''
                  }
                })
                this.formquery.sourceData.forEach((item) => {
                  !item.msg && this.$set(item, 'msg', '')
                })
                this.formquery.sourceData.forEach((item) => {
                  this.$set(item, 'msg', '')
                })
                // 先遍历出成功的，在遍历出索引
                resp.data.forEach((v) => {
                  if (v.msg === 'success') {
                    return
                  }
                  this.formquery.sourceData.forEach((t, index) => {
                    if (index === v.index) {
                      t.msg = v.msg
                    }
                  })
                })
                // 过滤
                this.formquery.sourceData = this.formquery.sourceData.filter((item) => item.msg)
                // 随机数
                this.$set(this.formquery.sourceData, 'num', Math.round(Math.random() * 10000))
              } else if (resp.code === 202) {
                this.msgVisible = true
                this.formquery.sourceData.forEach((item) => {
                  resp.data.forEach((x) => {
                    item.msg = x.msg
                  })
                })
                this.formquery.platAppId = this.appDelId
              }
            }
            this.isLoading = false
          })
        } else {
          this.$message.warning('请完成当前表单填写！')
        }
      })
    },
    handleFormquery () {
      this.$refs.formqueryRef.validate((valid) => {
        this.isFormqueryRule = !!valid
      })
    }
  }
}
</script>
<style lang="less" scoped>
@paddBottom: 80px;
@padLeft: 15px;
::v-deep {
  .ant-page-header {
    background: #fff;
    border-radius: 5px;
    padding: 7px 24px;
    .ant-page-header-heading {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    span {
      font-size: 14px;
    }
    .line {
      margin: 0 10px;
      background-color: gray;
      width: 2px;
      height: 12px;
      display: inline-block;
    }
  }
}
.kong {
  text-align: left;
}
.batchAdd-data-container {
  margin: -55px 0 10px;
  padding-bottom: @paddBottom;
  .info-board-container {
    border-radius: 5px;
    height: 100px;
    background: #fff;
    padding: 0 10px;
    margin: 0 10px 10px;
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    .source-info-item {
      max-width: 20%;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      .sourceInfo-title {
        height: 30%;
        // text-align: center;
        line-height: 50px;
        font-weight: 600;
        padding-left: @padLeft;
        color: #000;
        font-size: 16px;
      }
      .source-info-detail {
        flex-grow: 1;
        height: 0;
        box-sizing: border-box;
        position: relative;
        &.other {
          width: 100%;
          line-height: 55px;
          // text-align: center;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          padding: 0 10px;
          padding-left: @padLeft;
          font-size: 13px;
        }
        &.app {
          padding: 12px 40px;
          padding-left: @padLeft;
          .app-info-wrapper {
            height: 100%;
            position: relative;
            box-sizing: border-box;
            display: flex;
            .app-avatar {
              height: 100%;
              aspect-ratio: 1/1;
              border-radius: 5px;
              overflow: hidden;
              min-width: 42px;
              img {
                // aspect-ratio: 1/1;
                height: 40px;
                height: 40px;
                border-radius: 10px;
                // border: 1px solid #ccc;
              }
            }
            .app-info-detail {
              flex-grow: 1;
              padding: 0px 8px;
              position: relative;
              width: 100%;
              .app-name {
                width: 100%;
                font-size: 14px;
                line-height: 20px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                .os-icon {
                  width: 13px;
                  height: 13px;
                  vertical-align: top;
                  margin-top: 3px;
                }
              }
              .app-id {
                font-size: 12px;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }
  .batchAdd_table_wrapper {
    border-radius: 5px;
    width: 100%;
    margin-top: 10px;
    background: #fff;
    padding: 20px;
    margin-bottom: 50px;
    .control {
      padding: 5px;
      .ant-btn {
        padding: 0;
      }
    }
    .batchAdd_table {
      padding: 10px 10px 0;
    }
  }
}
::v-deep.batchAdd-data-container {
  .ant-page-header-back {
    font-size: 14px;
  }
  .form_item {
    display: flex;
    .ant-form-explain {
      width: 120px;
    }
  }
  .ant-table-content {
    .ant-table-scroll .ant-table-body .ant-table-fixed .ant-table-tbody {
      tr {
        height: 70px;
        td {
          padding: 0 12px;
          .ant-form-item {
            display: flex;
            margin-bottom: 0;
            height: 40px;
            margin-top: 5px;
            .ant-form-item-control {
              line-height: 20px;
            }
            .ant-form-explain,
            .ant-form-extra {
              min-height: 16px;
              line-height: 16px;
              font-size: 12px;
            }
            .ant-select {
              padding: 0;
            }
            .ant-switch {
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
  .ant-btn-group.ant-dropdown-button {
    .ant-btn {
      border-radius: 5px 0 0 5px;
      &.ant-dropdown-trigger {
        border-radius: 0 5px 5px 0;
      }
    }
  }
}
// /deep/ .ant-table-tbody > tr > td {
//   padding: 0px !important;
// }
.search-wrapper {
  height: 60px;
  .search_item {
    width: 180px;
    margin-left: 20px;
    margin-right: 10px;
  }
}
.table_item {
  display: flex;
  .ant-form-explain {
    width: 120px;
  }
}
.recommend-template-footer {
  z-index: 99;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  overflow: hidden;
  transition: all 0.8s;
  background: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-shadow: -3px -3px 10px 0px #ddd;
  padding: 0 10px;
  button {
    margin: 0 10px;
  }
}
</style>
<style lang="less">
.autoPop{
  .ant-popover-inner-content {
  color: @primary-color;
  padding: 7px 7px !important;
}
.ant-popover.autoPop {
  caret-color: transparent;
  left: 642px !important;
  transform-origin: 0px -4px !important;
  top: 281px !important;
  .ant-popover-inner {
    border: 1px solid @primary-color;
    background-color: #f1f4ff !important;
    border-radius: 4px;
  }
  .ant-popover-content {
    .ant-popover-arrow {
      background-color: #f0f5ff !important;
      border-left: 1px solid @primary-color;
      border-top: 1px solid @primary-color;
    }
  }
}
}
</style>
<style lang="less">
.method-modal-wrapper {
  &.ant-modal-confirm.ant-modal-confirm-confirm .ant-modal{
      margin-top: 200px;
  }
  .ant-modal-body {
    padding: 0 !important;
  }
  .ant-modal-confirm-body-wrapper {
    border: 1px solid;
    .ant-modal-confirm-title {
      height: 50px;
      line-height: 50px;
      padding: 0 10px;
      border-bottom: 1px solid #eee;
    }
    .ant-modal-confirm-content {
      border-bottom: 1px solid #eee;
    }
    .ant-modal-confirm-btns {
      margin-top: 14px;
      margin-bottom: 12px;
      margin-right: 10px;
    }
  }
}
</style>
